@font-face {
  font-family: Nunito;
  src: local('Nunito'), local('Nunito-Regular'),
    url('../../shared/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: local('Nunito'), local('Nunito-Semi-Bold'),
    url('../../shared/assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: local('Nunito'), local('Nunito-Bold'),
    url('../../shared/assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik Mono One';
  src: local('Rubik Mono One'),
  url('../../shared/assets/fonts/Rubik/RubikMonoOne-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
